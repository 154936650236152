import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import SubmitButton from '@/shared/ui/SubmitButton';
import { janusStore } from '@/shared/model/janus';
export const HandleJanusConnection = observer(() => {
    const { localStream, removeSession, reconnect } = janusStore;
    if (!removeSession || !reconnect) {
        return null;
    }
    return localStream ? (_jsx(SubmitButton, { variant: 'contained', onClick: removeSession, children: "Disconnect from Janus" })) : (_jsx(SubmitButton, { variant: 'contained', onClick: reconnect, children: "Connect to Janus" }));
});
