import styled from 'styled-components';
export const Heading = styled.h1 `
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
`;
export const Text = styled.p `
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;
export const HelperText = styled.p `
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #8f8f8f;
`;
export const List = styled.ul `
  display: flex;
  flex-direction: column;

  gap: 8px;

  & li {
    list-style: disc;
  }

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;
