import { makeAutoObservable, runInAction } from 'mobx';
import { RoomReconnectLogic } from '../types/room-reconnect-logic';
import StorageService from '@/shared/lib/utils/storage';
import { STORAGE_KEYS } from '@/shared/constants/common';
const CAN_RECONNECT_TIMEOUT = 10 * 1000;
class RoomReconnectStore {
    _reconnectionCount = 0;
    _roomReconnectLogic = StorageService.get(STORAGE_KEYS.roomReconnect, false) ??
        RoomReconnectLogic.WITH_PAGE_RELOAD;
    _reconnectInfo = null;
    _canReconnect = false;
    _isReconnectsLimitExceeded = false;
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get reconnectionCount() {
        return this._reconnectionCount;
    }
    set reconnectionCount(reconnectionCount) {
        this._reconnectionCount = reconnectionCount;
    }
    get roomReconnectLogic() {
        return this._roomReconnectLogic;
    }
    set roomReconnectLogic(roomReconnectLogic) {
        this._roomReconnectLogic = roomReconnectLogic;
    }
    set reconnectInfo(reconnectInfo) {
        if (!reconnectInfo) {
            this.disableReconnect();
        }
        this._reconnectInfo = reconnectInfo;
    }
    get reconnectInfo() {
        return this._reconnectInfo;
    }
    get isReconnectsLimitExceeded() {
        return this._isReconnectsLimitExceeded;
    }
    set isReconnectsLimitExceeded(isReconnectsLimitExceeded) {
        this._isReconnectsLimitExceeded = isReconnectsLimitExceeded;
    }
    get canReconnect() {
        return this._canReconnect;
    }
    disableReconnect = () => {
        setTimeout(() => {
            runInAction(() => {
                this._canReconnect = true;
            });
        }, CAN_RECONNECT_TIMEOUT);
        this._canReconnect = false;
    };
}
export const roomReconnectStore = new RoomReconnectStore();
roomReconnectStore.disableReconnect();
