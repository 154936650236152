import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { roomReconnectStore } from '../../model/store';
import { useRoomDisconnect } from '@/shared/lib/hooks';
import { ReceiveInteractionsMessages, sendInteraction, SendInteractionsMessages, useReceiveInteraction, } from '@/shared/model/interactions';
import { ROUTES } from '@/shared/constants/routes';
import { janusStore } from '@/shared/model/janus';
const CLEAR_RECONNECTION_COUNT_TIMEOUT_MS = 10 * 1000;
export const useRoomReconnect = (roomId) => {
    const { removeSession, janus: { ready }, } = janusStore;
    const { reconnectInfo, reconnectionCount } = roomReconnectStore;
    const timeoutRef = useRef();
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    useReceiveInteraction({
        method: ReceiveInteractionsMessages.fixStreamId,
        callback: ({ streamId }) => {
            if (reconnectInfo?.streamId !== streamId) {
                if (reconnectionCount > 2) {
                    (async () => {
                        await disconnect();
                        navigate(ROUTES.rooms.path);
                    })();
                    return;
                }
                roomReconnectStore.reconnectionCount = reconnectionCount + 1;
                roomReconnectStore.reconnectInfo = {
                    streamId,
                    completed: false,
                };
                removeSession?.();
                janusStore.clear();
                sendInteraction({
                    method: SendInteractionsMessages.gotFixStreamId,
                });
                // if (roomReconnectLogic === RoomReconnectLogic.WITH_PAGE_RELOAD) {
                //   (async () => {
                //     await disconnect();
                //     navigate(ROUTES.rooms.path);
                //     setTimeout(() => {
                //       navigate(`${ROUTES.rooms.path}/${roomId}`);
                //     }, 500);
                //   })();
                // }
            }
        },
    });
    useEffect(() => {
        if (reconnectionCount > 0 && ready) {
            timeoutRef.current = setTimeout(() => {
                roomReconnectStore.reconnectionCount = 0;
                roomReconnectStore.reconnectInfo = null;
            }, CLEAR_RECONNECTION_COUNT_TIMEOUT_MS);
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [ready, reconnectionCount]);
};
