import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useNotificationHubReceive, useRoomDisconnect } from '@/shared/lib/hooks';
import { HUB_METHODS } from '@/shared/constants/hub';
import { modalStore } from '@/shared/model/modal';
import SpeedDatingStartedNotification from '@/entities/notifications/SpeedDatingStartedNotification';
import { roomStore } from '@/shared/model/room';
import { ROUTES } from '@/shared/constants/routes';
import SpeedDatingEndedNotification from '@/entities/notifications/SpeedDatingEndedNotification';
import { speedDatingStore } from '@/shared/model/speed-dating';
const useSpeedDatingStartedHandler = (userRole) => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    useNotificationHubReceive(HUB_METHODS.notification.receive.speedDatingEventStarted, (message) => {
        const { room: { id }, } = roomStore;
        if (userRole === 'performer') {
            return;
        }
        if (id === message.roomID) {
            return;
        }
        speedDatingStore.isClosed = false;
        modalStore.open({
            content: _jsx(SpeedDatingStartedNotification, { roomId: message.roomID }),
        });
    });
    useNotificationHubReceive(HUB_METHODS.notification.receive.speedDatingEventEnded, async (message) => {
        const { room: { id }, } = roomStore;
        if (userRole === 'performer') {
            return;
        }
        speedDatingStore.isClosed = true;
        if (id === message.roomID) {
            await disconnect();
            navigate(ROUTES.rooms.path);
            modalStore.open({
                content: _jsx(SpeedDatingEndedNotification, {}),
            });
        }
    });
};
export default useSpeedDatingStartedHandler;
