import axiosInstance from '../http';
import { API } from '@/shared/constants/api';
import { ROLES } from '@/shared/constants/roles';
import ServiceBuilder from '@/shared/lib/utils/service';
const loginByRole = async ({ role, ...params }) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(role === ROLES.user ? API.login : API.loginPerformers, params);
    return response;
});
const validatePasswordResetCode = async ({ role, ...params }) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(role === ROLES.user ? API.validateCode : API.performerValidateCode, params);
    return response;
});
const AuthService = {
    loginByRole,
    validatePasswordResetCode,
};
export default AuthService;
