import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, IconButton } from '@mui/material';
import { memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { ControlButtonsWrapper, Wrapper } from './styled';
import { VisibilityOffIcon, VisibilityIcon } from '@/shared/assets/icons';
import { userStore } from '@/shared/model/user';
import { ROUTES } from '@/shared/constants/routes';
import { headerStore } from '@/shared/model/header';
import { roomStore } from '@/shared/model/room';
import { AddNewUser } from '@/features/admin-add-user';
import { HandleJanusConnection } from '@/features/admin-handle-janus';
import { janusStore } from '@/shared/model/janus';
import { ToggleSpeedDatingMatch } from '@/features/admin-toggle-speed-dating-match';
const AdminControls = observer(() => {
    const { pathname } = useLocation();
    const isLobby = pathname === ROUTES.rooms.path;
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    const { user } = userStore;
    const { showControls, showIds } = headerStore;
    const { room, tables } = roomStore;
    const handleChangeShowIds = useCallback(() => {
        headerStore.toggleShowIds();
        console.group('_admin info_'); // eslint-disable-line
        console.groupCollapsed('_room info_'); // eslint-disable-line
        console.log(JSON.stringify(room, undefined, 2)); // eslint-disable-line
        console.groupEnd(); // eslint-disable-line
        console.groupCollapsed('_tables info_'); // eslint-disable-line
        console.log(JSON.stringify(tables, undefined, 2)); // eslint-disable-line
        console.groupEnd(); // eslint-disable-line
        console.groupCollapsed('_janus.streams info_'); // eslint-disable-line
        console.log(JSON.stringify(janusStore.streams, undefined, 2)); // eslint-disable-line
        console.groupEnd(); // eslint-disable-line
        console.groupEnd(); // eslint-disable-line
    }, [room, tables]);
    return !user.isAdmin || isLobby || isDashboard || !showControls ? null : (_jsxs(Wrapper, { children: [_jsx(Tooltip, { title: _jsxs(ControlButtonsWrapper, { children: [_jsx(HandleJanusConnection, {}), _jsx(AddNewUser, {}), room.isSpeedDating && _jsx(ToggleSpeedDatingMatch, {})] }), onClick: handleChangeShowIds, children: _jsx(IconButton, { size: 'small', color: 'white', children: "Actions" }) }), _jsx(Tooltip, { title: showIds ? "Hide id's" : "Show id's", onClick: handleChangeShowIds, children: _jsx(IconButton, { size: 'small', color: 'white', children: showIds ? _jsx(VisibilityIcon, {}) : _jsx(VisibilityOffIcon, { color: 'error' }) }) })] }));
});
export default memo(AdminControls);
