import styled, { css } from 'styled-components';
export const HeaderTitle = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css `
    gap: ${theme.spacing(5)};
  `}
`;
export const ChannelBadge = styled.span `
  ${({ theme }) => css `
    background-color: ${theme.palette.red.main};
    padding: ${theme.spacing(0.5, 6)};
  `}
  display: flex;
  border-radius: 34px;
  text-transform: uppercase;
`;
