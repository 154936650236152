import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RestrictionWrapper, RestrictionText, BackWrapper, Title, Content } from './styled';
import { LogoIcon, ArrowBackIcon } from '@/shared/assets/icons';
import { BRAND_NAME } from '@/shared/constants/common';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore } from '@/shared/model/modal';
import RouteLink from '@/shared/ui/RouteLink';
const MobileRestriction = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleClose = useCallback(() => {
        modalStore.close();
        if (pathname === ROUTES.signUp.path) {
            navigate(-1);
        }
    }, [navigate, pathname]);
    return (_jsxs(Content, { children: [_jsx(LogoIcon, { color: 'primary' }), _jsx(Title, { children: "We\u2019re sorry!" }), _jsxs(RestrictionWrapper, { children: [_jsx(RestrictionText, { children: `${BRAND_NAME} is currently not available on mobile devices.` }), _jsx(RestrictionText, { children: "Please use your nearest desktop device to join our party :)" })] }), _jsx(BackWrapper, { children: _jsxs(RouteLink, { to: ROUTES.home.path, color: 'black', onClick: handleClose, children: [_jsx(ArrowBackIcon, {}), " Back to Site"] }) })] }));
};
export default memo(MobileRestriction);
