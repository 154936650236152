import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { GlobalStyle } from './styled';
import Layout from './Layout';
import ScrollToTop from '../entities/ScrollToTop';
import ErrorBoundary from '@/entities/ErrorBoundary';
import theme from '@/shared/lib/theme';
import { PAGES, PAGES_PROTECTED } from '@/pages/index';
import ProtectedRoute from '@/features/ProtectedRoute';
import { Broadcast } from '@/entities/broadcast';
import { NotificationSnackbar } from '@/shared/ui/NotificationSnackbar';
const App = () => (_jsx(ErrorBoundary, { children: _jsx(ScrollToTop, { children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(MuiThemeProvider, { theme: theme, children: _jsxs(ScThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(GlobalStyle, {}), _jsx(SnackbarProvider, { maxSnack: 3, Components: {
                                notification: NotificationSnackbar,
                            }, children: _jsx(Broadcast, { children: _jsx(Routes, { children: _jsxs(Route, { path: '/', element: _jsx(Layout, {}), children: [PAGES.map(({ path, component }) => (_jsx(Route, { path: path, element: component }, path))), _jsx(Route, { element: _jsx(ProtectedRoute, {}), children: PAGES_PROTECTED.map(({ path, component }) => (_jsx(Route, { path: path, element: component }, path))) })] }) }) }) })] }) }) }) }) }));
export default memo(App);
