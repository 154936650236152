import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentActions, ContentDescription, ContentHeading, ModalContent } from './styled';
import { modalStore } from '@/shared/model/modal';
import TextElement from '@/shared/ui/TextElement';
import ButtonCustom from '@/shared/ui/ButtonCustom';
import { ROUTES } from '@/shared/constants/routes';
import { useRoomDisconnect } from '@/shared/lib/hooks';
const SpeedDatingStartedNotification = ({ roomId, }) => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    const handleClose = () => {
        modalStore.close();
    };
    const handleMoveToSpeedDating = () => {
        modalStore.close();
        (async () => {
            await disconnect();
            navigate(ROUTES.rooms.path);
            setTimeout(() => {
                navigate(`${ROUTES.rooms.path}/${roomId}`);
            }, 500);
        })();
    };
    return (_jsxs(ModalContent, { width: 300, children: [_jsx(ContentHeading, { children: _jsx(TextElement, { fontSize: 24, fontWeight: 600, component: 'h6', children: "Speed Dating event begins now!" }) }), _jsx(ContentDescription, { children: _jsxs(TextElement, { fontSize: 16, children: ["Our speed dating event is starting now!", _jsx("br", {}), "Are you ready to enter the event room and discover an exciting date tailored to your preferences?"] }) }), _jsxs(ContentActions, { children: [_jsx(ButtonCustom, { variant: 'outlined', onClick: handleClose, height: 48, width: 162, children: "Nevermind" }), _jsx(ButtonCustom, { variant: 'contained', onClick: handleMoveToSpeedDating, height: 48, width: 162, children: "Yes, move" })] })] }));
};
export default memo(SpeedDatingStartedNotification);
