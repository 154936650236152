import { useState } from 'react';
import useWindowEvent from './useWindowEvent';
const useWindowSize = (delay = 100) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useWindowEvent('resize', () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }, delay);
    return windowSize;
};
export default useWindowSize;
