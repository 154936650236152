import { makeAutoObservable } from 'mobx';
import { STORAGE_KEYS } from '@/shared/constants/common';
import StorageService from '@/shared/lib/utils/storage';
class UserPreferencesStore {
    _stageVolume = 10;
    _roomBackgroundMusicVolume = +(StorageService.get(STORAGE_KEYS.backgroundMusicVolume) ?? 0.125);
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    get stageVolume() {
        return this._stageVolume;
    }
    set stageVolume(stageVolume) {
        this._stageVolume = stageVolume;
    }
    get roomBackgroundMusicVolume() {
        return this._roomBackgroundMusicVolume;
    }
    set roomBackgroundMusicVolume(volume) {
        this._roomBackgroundMusicVolume = volume;
    }
}
export const userPreferencesStore = new UserPreferencesStore();
