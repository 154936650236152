import { makeAutoObservable, runInAction } from 'mobx';
import { isAxiosError } from 'axios';
import RoomService from '@/shared/api/services/RoomService';
const emptyRoom = {
    countSeats: 0,
    countTables: 0,
    countUsers: 0,
    id: 0,
    janusRoomId: '',
    roomName: '',
    theme: 'default',
    maxCapacity: 0,
    image: '',
    sexualOrientation: '',
    description: '',
    isSpeedDating: false,
    isReoccurring: false,
    openDate: '',
    question: '',
};
class RoomStore {
    _room = emptyRoom;
    _roomTheme = 'default';
    _preLogin = true;
    _tables = [];
    _loading = true;
    _stageQueue = [];
    _isPerformer = false;
    _eventData = null;
    _eventStarted = false;
    _isRoomWithControls = true;
    _question = '';
    _barTables = [];
    _regularTables = [];
    _stageTable = null;
    _users = [];
    _newUser = null;
    _newUserSeatEl = null;
    _questionAnswers = [];
    _visibleUsers = [];
    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }
    set room(room) {
        if (!room)
            return;
        this._room = room;
    }
    get room() {
        return this._room;
    }
    set tables(tables) {
        if (!tables)
            return;
        this._tables = tables;
    }
    get tables() {
        return this._tables;
    }
    set questionAnswers(answers) {
        this._questionAnswers = answers;
    }
    get questionAnswers() {
        return this._questionAnswers;
    }
    set barTables(barTables) {
        if (!barTables)
            return;
        this._barTables = barTables;
    }
    get barTables() {
        return this._barTables;
    }
    set regularTables(regularTables) {
        if (!regularTables)
            return;
        this._regularTables = regularTables;
    }
    get regularTables() {
        return this._regularTables;
    }
    set stageTable(stageTable) {
        if (!stageTable)
            return;
        this._stageTable = stageTable;
    }
    get stageTable() {
        return this._stageTable;
    }
    set preLogin(preLogin) {
        this._preLogin = preLogin;
    }
    get preLogin() {
        return this._preLogin;
    }
    set roomTheme(theme) {
        if (this._roomTheme !== theme) {
            this._roomTheme = theme;
        }
    }
    get roomTheme() {
        return this._roomTheme;
    }
    set stageQueue(stageQueue) {
        this._stageQueue = stageQueue;
    }
    get stageQueue() {
        return this._stageQueue;
    }
    set isRoomWithControls(isRoomWithControls) {
        this._isRoomWithControls = isRoomWithControls;
    }
    get isRoomWithControls() {
        return this._isRoomWithControls;
    }
    set eventData(eventData) {
        this._eventData = eventData;
    }
    get eventData() {
        return this._eventData;
    }
    set loading(loading) {
        this._loading = loading;
    }
    get loading() {
        return this._loading;
    }
    set isPerformer(isPerformer) {
        this._isPerformer = isPerformer;
    }
    get isPerformer() {
        return this._isPerformer;
    }
    set eventStarted(eventStarted) {
        this._eventStarted = eventStarted;
    }
    get eventStarted() {
        return this._eventStarted;
    }
    set question(question) {
        this._question = question;
    }
    get question() {
        return this._question;
    }
    set users(users) {
        this._users = users;
    }
    get users() {
        return this._users;
    }
    set visibleUsers(visibleUsers) {
        this._visibleUsers = visibleUsers;
    }
    get visibleUsers() {
        return this._visibleUsers;
    }
    set newUser(newUser) {
        this._newUser = newUser;
    }
    get newUser() {
        return this._newUser;
    }
    set newUserSeatEl(newUserSeatEl) {
        this._newUserSeatEl = newUserSeatEl;
    }
    get newUserSeatEl() {
        return this._newUserSeatEl;
    }
    init = async (roomId) => {
        const response = await RoomService.getRoomDetails(roomId);
        if (response && !isAxiosError(response)) {
            runInAction(() => {
                this._room = {
                    ...response.data,
                };
            });
        }
    };
    clear = () => {
        this._room = emptyRoom;
        this._preLogin = true;
        this._tables = [];
        this._barTables = [];
        this._regularTables = [];
        this._stageTable = null;
        this.users = [];
    };
}
export const roomStore = new RoomStore();
