import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Heading, Text } from './styled';
import { modalStore } from '@/shared/model/modal';
import ButtonCustom from '@/shared/ui/ButtonCustom';
import { ModalContent, ModalContentActions, ModalContentHeading } from '@/shared/ui/ModalContent';
const InnerReconnectsLimitModal = () => {
    const handleSubmit = () => {
        modalStore.close();
    };
    return (_jsxs(ModalContent, { width: 820, children: [_jsx(ModalContentHeading, { children: _jsx(Heading, { children: "Reconnecting problem :(" }) }), _jsxs(Text, { children: ["You had to much attempts to reconnect.", _jsx("br", {}), "Please, fix yours internet problem and return back to action when everything is ready!"] }), _jsx(ModalContentActions, { children: _jsx(ButtonCustom, { variant: 'contained', onClick: handleSubmit, height: 48, width: 162, children: "Got it!" }) })] }));
};
export const ReconnectsLimitModal = memo(InnerReconnectsLimitModal);
