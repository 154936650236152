import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { memo, useCallback } from 'react';
import { HeaderTitle } from './styled';
import { LobbyIcon } from '@/shared/assets/icons';
import { ROLES } from '@/shared/constants/roles';
import { ROUTES } from '@/shared/constants/routes';
import { useRoomDisconnect } from '@/shared/lib/hooks';
import { headerStore } from '@/shared/model/header';
import { modalStore } from '@/shared/model/modal';
import { roomStore } from '@/shared/model/room';
import { userStore } from '@/shared/model/user';
import TextElement from '@/shared/ui/TextElement';
import ChangeTable from '@/entities/notifications/ChangeTable';
import CustomTooltip from '@/shared/ui/CustomTooltip';
import { speedDatingStore } from '@/shared/model/speed-dating';
import { sendInteraction, SendInteractionsMessages } from '@/shared/model/interactions';
import { roomReconnectStore } from '@/shared/model/room-reconnect';
const RoomActions = observer(() => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    const { isMatched, isInDate } = speedDatingStore;
    const { room: { maxCapacity, isSpeedDating, roomName: baseRoomName }, isRoomWithControls, users, } = roomStore;
    const { user: { role }, } = userStore;
    const { showControls } = headerStore;
    const { reconnectInfo } = roomReconnectStore;
    const roomName = baseRoomName || reconnectInfo?.roomName;
    const handleSubmit = useCallback(async () => {
        await disconnect();
        roomReconnectStore.reconnectInfo = null;
        const feedbackState = role !== ROLES.performer
            ? {
                state: {
                    withFeedback: true,
                },
            }
            : undefined;
        navigate(role !== ROLES.performer ? ROUTES.rooms.path : ROUTES.speakerDashboard.path, feedbackState);
    }, [disconnect, navigate, role]);
    const handleDisconnect = useCallback(() => {
        if (reconnectInfo && !reconnectInfo.completed) {
            modalStore.open({
                content: (_jsx(ChangeTable, { title: 'Leave room?', confirmLabel: 'Yes, leave room', buttonsFullWidth: true, onSubmit: handleSubmit, message: 'Leaving the room means losing all sent and received interactions with other users' })),
            });
            return;
        }
        if (showControls) {
            if (isSpeedDating) {
                modalStore.open({
                    content: (_jsx(ChangeTable, { title: 'Leave room?', confirmLabel: 'Yes, leave room', declineLabel: 'No, stay', buttonsFullWidth: true, onSubmit: handleSubmit, message: 'Are you sure that you want to leave this room?' })),
                });
            }
            else {
                modalStore.open({
                    content: (_jsx(ChangeTable, { title: 'Leave room?', confirmLabel: 'Yes, leave room', buttonsFullWidth: true, onSubmit: handleSubmit, message: 'Leaving the room means losing all sent and received interactions with other users' })),
                });
            }
            return;
        }
        navigate(role !== ROLES.performer ? ROUTES.rooms.path : ROUTES.speakerDashboard.path);
    }, [reconnectInfo, showControls, navigate, role, handleSubmit, isSpeedDating]);
    const handleLeaveSpeedDatingMatch = useCallback(() => {
        sendInteraction({
            method: SendInteractionsMessages.returnToSpeedDating,
        });
        speedDatingStore.isMatched = false;
    }, []);
    const handleReturnToSpeedDatingQueueClick = useCallback(() => {
        modalStore.open({
            content: (_jsx(ChangeTable, { title: 'Leave date?', confirmLabel: 'Yes, leave date', declineLabel: 'No, stay', buttonsFullWidth: true, onSubmit: handleLeaveSpeedDatingMatch, message: 'Are you sure that you want to leave this date?' })),
        });
    }, [handleLeaveSpeedDatingMatch]);
    return roomName ? (_jsxs(_Fragment, { children: [_jsxs(HeaderTitle, { children: [isSpeedDating && (isMatched || isInDate) ? (_jsx(CustomTooltip, { title: 'Return to speed dating queue', placement: 'bottom', children: _jsx(IconButton, { color: 'white', onClick: handleReturnToSpeedDatingQueueClick, "aria-label": 'Return to lobby', children: _jsx(LobbyIcon, { width: 27, height: 18 }) }) })) : (_jsx(CustomTooltip, { title: 'Return to Lobby', placement: 'bottom', children: _jsx(IconButton, { color: 'white', onClick: handleDisconnect, "aria-label": 'Return to lobby', children: _jsx(LobbyIcon, { width: 27, height: 18 }) }) })), showControls ? (_jsx(TextElement, { fontSize: 24, fontWeight: 600, noWrap: true, children: roomName })) : null] }), users.length && maxCapacity && isRoomWithControls && showControls ? (_jsx(TextElement, { fontSize: 16, children: `${users.length}/${maxCapacity}` })) : null] })) : null;
});
export default memo(RoomActions);
