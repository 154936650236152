import styled, { css } from 'styled-components';
export const Content = styled.section `
  display: flex;
  position: relative;
  flex-direction: column;
  height: 475px;
  width: 340px;
  padding: 35px;
  align-items: center;
  justify-content: normal;
  gap: 25px;

  ${({ theme }) => css `
    ${theme.breakpoints.down('sm')} {
      max-width: 90vw;
    }
  `}
`;
export const Title = styled.h4 `
  text-align: center;
  ${({ theme }) => css `
    font-size: ${theme.util.pxToRem(24)};
    color: ${theme.palette.black.main};
  `}
`;
export const RestrictionText = styled.p `
  ${({ theme }) => css `
    text-align: center;
    font-size: ${theme.util.pxToRem(16)};
    color: ${theme.palette.black.main};
    line-height: 1.5;
  `}
`;
export const RestrictionWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BackWrapper = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: end;
`;
