import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { BinocularsIcon } from '@/shared/assets/icons';
export const StreamControls = styled.div `
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LookingOnMeMark = styled(IconButton) `
  animation: heartbeat 2s linear infinite;
`;
export const LookingOnMeMarkIcon = styled(BinocularsIcon) `
  width: 35px;
  height: 35px;

  color: #a041ff;

  animation: heartbeat 2s ease-in-out infinite;
`;
