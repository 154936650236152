import ServiceBuilder from '@/shared/lib/utils/service';
import axiosInstance from '@/shared/api/http';
import { API } from '@/shared/constants/api';
import { userStore } from '@/shared/model/user';
import { roomStore } from '@/shared/model/room';
import { janusStore } from '@/shared/model/janus';
const saveJanusMessage = async ({ transaction, body }) => ServiceBuilder.serviceRequest(async () => {
    const { user } = userStore;
    const { room } = roomStore;
    const response = await axiosInstance.post(API.saveJanusMessage, {
        userId: user.id,
        roomId: room.id,
        transaction,
        body: JSON.stringify(body),
    });
    return response;
});
const saveJanusMessageResponse = async ({ transaction, body, }) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.saveJanusMessageResponse, {
        transaction,
        body: JSON.stringify(body),
    });
    return response;
});
const saveReportLog = async () => ServiceBuilder.serviceRequest(async () => {
    const { user } = userStore;
    const { id } = user;
    const { streams } = janusStore;
    const { tables, room } = roomStore;
    const response = await axiosInstance.post(API.saveReportLog, {
        userId: id,
        roomId: room.id,
        streams: JSON.stringify(streams),
        tables: JSON.stringify(tables),
    });
    return response;
});
const saveLogMessage = async (message) => ServiceBuilder.serviceRequest(async () => {
    const response = await axiosInstance.post(API.saveLogMessage, {
        message,
    });
    return response;
});
const DebugService = {
    saveJanusMessage,
    saveJanusMessageResponse,
    saveReportLog,
    saveLogMessage,
};
export default DebugService;
