import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar, Toolbar, LeftContainer, LogoButton } from './styled';
import RoomActions from './model/RoomActions';
import ViewModeControls from './components/ViewModeControls';
import { useOffline } from './lib/hooks/useOffline';
import { LogoIcon } from '@/shared/assets/icons';
import { ROUTES } from '@/shared/constants/routes';
import UserActions from '@/widgets/RoomHeader/model/UserActions';
import { roomReconnectStore } from '@/shared/model/room-reconnect';
const RoomHeader = observer(() => {
    const { pathname } = useLocation();
    const { reconnectInfo } = roomReconnectStore;
    const isLobby = pathname === ROUTES.rooms.path && !reconnectInfo;
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    useOffline();
    /* Commented code until we decide what Logo click must do
    // const isRoom = pathname.startsWith(`${ROUTES.rooms.path}/`);
    //
    // const handleSubmit = useCallback(() => {
    //   navigate(ROUTES.home.path);
    // }, [navigate]);
  
    //
    // const handleLogoClick = useCallback(() => {
    //   if (isRoom) {
    //     modalStore.open({
    //       content: (
    //         <ChangeTableForm
    //           title='Leave room?'
    //           confirmLabel='Yes, leave'
    //           onSubmit={handleSubmit}
    //           message='Are you sure that you want to return back?'
    //           optionalMessage='All pending interactions from other room users will be lost.'
    //         />
    //       ),
    //     });
    //     return;
    //   }
    //   handleSubmit();
    // }, [handleSubmit, isRoom]);
    */
    // const ariaLabel = `to ${isRoom ? 'lobby' : 'homepage'}`;
    return (_jsx(AppBar, { bordered: isLobby || isDashboard, children: _jsxs(Toolbar, { children: [_jsxs(LeftContainer, { children: [isLobby || isDashboard ? (_jsx(LogoButton, { color: 'primary', children: _jsx(LogoIcon, { color: 'primary' }) })) : null, _jsx(RoomActions, {})] }), isLobby || isDashboard ? null : _jsx(ViewModeControls, {}), _jsx(UserActions, {})] }) }));
});
export default memo(RoomHeader);
