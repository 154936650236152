import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import SubmitButton from '@/shared/ui/SubmitButton';
const InnerAddNewUser = observer(() => (
// const addUser = useAddUser();
// const handleConnectNewUser = useCallback(() => {
//   modalStore.open({
//     content: <AddUserToRoomForm {...addUser} />,
//   });
// }, [addUser]);
_jsx(SubmitButton, { variant: 'contained', onClick: () => { }, children: "Connect new user" })));
export const AddNewUser = memo(InnerAddNewUser);
