export const BRAND_NAME = 'Realush';
export const BRAND_EMAIL = 'idan@f-narrative.co.il';
export const STORAGE_KEYS = {
    user: 'User',
    accessToken: 'AccessToken',
    messageSound: 'MessageSound',
    orientation: 'Orientation',
    ageGroup: 'AgeGroup',
    note: 'Note',
    noteBank: 'NoteBank',
    feedbackModalCount: 'Feedback modal count',
    speedDatingFilters: 'Speed Dating filters',
    backgroundMusicVolume: 'Background music volume',
    roomReconnect: 'Room reconnect logic',
};
export const SOCIAL_MEDIA = {
    facebook: 'https://www.facebook.com/RealushDating',
    instagram: 'https://www.instagram.com/realushdating',
    linkedin: 'https://www.linkedin.com/company/realush',
};
export const CHAT_USER_COLORS = [
    'red',
    'green',
    'blue',
    'yellow',
    'blueviolet',
    'coral',
    'darkgreen',
    'darkgray',
];
export var EventType;
(function (EventType) {
    EventType[EventType["SPEED_DATING"] = 19] = "SPEED_DATING";
})(EventType || (EventType = {}));
