import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTES } from '@/shared/constants/routes';
import MainHeader from '@/widgets/MainHeader';
import RoomHeader from '@/widgets/RoomHeader';
import { useNotificationHubReceive, useSignOut } from '@/shared/lib/hooks';
import { HUB_METHODS } from '@/shared/constants/hub';
const DynamicHeader = observer(() => {
    const { pathname } = useLocation();
    const { signOut } = useSignOut();
    useNotificationHubReceive(HUB_METHODS.notification.receive.logout, async (_) => {
        await signOut();
    });
    const notDefault = pathname.startsWith(ROUTES.rooms.path) || pathname.startsWith(ROUTES.speakerDashboard.path);
    return notDefault ? _jsx(RoomHeader, {}) : _jsx(MainHeader, {});
});
export default memo(DynamicHeader);
