import { makeAutoObservable } from 'mobx';
class UserMediaStore {
    _userMediaStream = null;
    _audioId = '';
    _videoId = '';
    constructor() {
        makeAutoObservable(this);
    }
    get userMediaStream() {
        return this._userMediaStream;
    }
    setUserMediaStream = (userMediaStream) => {
        if (this._userMediaStream) {
            this._userMediaStream.getTracks().forEach((t) => t.stop());
        }
        this._userMediaStream = userMediaStream;
    };
    get audioId() {
        return this._audioId;
    }
    setAudioId = (audioId) => {
        this._audioId = audioId;
    };
    get videoId() {
        return this._videoId;
    }
    setVideoId = (videoId) => {
        this._videoId = videoId;
    };
    clear = () => {
        if (this._userMediaStream) {
            this._userMediaStream.getTracks().forEach((t) => t.stop());
        }
        this._userMediaStream = null;
        this._audioId = '';
        this._videoId = '';
    };
}
export const userMediaStore = new UserMediaStore();
