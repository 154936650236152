import styled, { css } from 'styled-components';
export const Heading = styled.h1 `
  font-family: 'Poppins', serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
`;
export const Text = styled.p(({ withExtraMargin }) => css `
    width: 100%;
    font-family: 'Poppins', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    ${withExtraMargin &&
    css `
      margin-top: 6px;
    `}
  `);
