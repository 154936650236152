import { Card, Avatar } from '@mui/material';
import styled, { css } from 'styled-components';
export const SnackbarMessageContainer = styled.div(({ theme }) => css `
    display: flex;
    flex-direction: column;
    padding-right: ${theme.spacings.xxs};
    justify-content: center;
  `);
export const UserPicture = styled(Avatar) `
  height: 50px;
  width: 50px;
`;
export const CardStyled = styled(Card)(({ theme, color = 'white' }) => css `
    min-width: 600px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${theme.palette[color].main};
    border-radius: ${theme.radiuses.s};
    padding: ${theme.spacings.m} ${theme.spacings.l};
  `);
export const CardContent = styled.div(({ theme }) => css `
    display: flex;
    gap: ${theme.spacings.s};
    justify-content: center;
    align-items: center;
  `);
