import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, ListItem, Popover } from '@mui/material';
import { memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Wrapper, UserActionsWrapper, ListStyled, ItemButton, UserAvatar } from './styled';
import FriendsButton from '../../components/FriendsButton';
import ChatButton from '../../components/ChatButton';
import UserStreamControls from '../../components/UserStreamControls';
import AdminControls from '../../components/AdminControls';
import { http } from '@/shared/api/http';
import { ROLES } from '@/shared/constants/roles';
import { ROUTES } from '@/shared/constants/routes';
import { useSignOut, usePopoverAnchor } from '@/shared/lib/hooks';
import { modalStore } from '@/shared/model/modal';
import { userStore } from '@/shared/model/user';
import Profile from '@/widgets/Profile';
import { avatarPlaceholder } from '@/shared/assets/images';
import DebugService from '@/shared/api/services/DebugService';
import { roomStore } from '@/shared/model/room';
const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};
const UserActions = observer(() => {
    const { pathname } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { signOut } = useSignOut();
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    const { user } = userStore;
    const { role, profilePicture } = user;
    const { room } = roomStore;
    const src = role === ROLES.user
        ? profilePicture
        : `${http.getImagesUrl()}/UsersPerformers/${profilePicture}`;
    const { open, anchorEl, handleOpen, handleClose } = usePopoverAnchor();
    const handleProfileClick = () => {
        handleClose();
        modalStore.open({
            content: _jsx(Profile, {}),
        });
    };
    const handleSecurityClick = () => {
        handleClose();
        modalStore.open({
            content: _jsx(Profile, { security: true }),
        });
    };
    const handleReportBugClick = useCallback(() => {
        handleClose();
        if (!room.id) {
            enqueueSnackbar("You're not in the room!", {
                autoHideDuration: 3000,
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom',
                },
            });
            return;
        }
        enqueueSnackbar('Report sent!', {
            autoHideDuration: 3000,
            variant: 'info',
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
            },
        });
        DebugService.saveReportLog();
    }, [enqueueSnackbar, handleClose, room.id]);
    const handleSignOut = () => {
        signOut();
    };
    return (_jsxs(Wrapper, { children: [_jsx(AdminControls, {}), _jsx(UserStreamControls, {}), _jsxs(UserActionsWrapper, { children: [isDashboard ? null : (_jsxs(_Fragment, { children: [_jsx(ChatButton, {}), _jsx(FriendsButton, {})] })), _jsx(IconButton, { size: 'small', onClick: handleOpen, children: _jsx(UserAvatar, { alt: 'You', src: profilePicture ? src : avatarPlaceholder }) })] }), _jsx(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: anchorOrigin, children: _jsxs(ListStyled, { children: [_jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleProfileClick, children: "Profile" }) }), _jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { component: 'a', href: ROUTES.contactUs.path, target: '_blank', onClick: handleClose, children: "Help" }) }), _jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleSecurityClick, children: "Settings & Privacy" }) }), _jsx(ListItem, { disableGutters: true, children: _jsx(ItemButton, { onClick: handleReportBugClick, style: { color: 'red' }, children: "Report bug" }) }), _jsx(ListItem, { disableGutters: true, onClick: handleSignOut, children: _jsx(ItemButton, { children: "Sign Out" }) })] }) })] }));
});
export default memo(UserActions);
