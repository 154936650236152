import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRoomDisconnect } from '@/shared/lib/hooks';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore } from '@/shared/model/modal';
import { OfflineModal } from '@/entities/offline-modal';
const ON_OFFLINE_ACTION_DELAY_MS = 3000;
export const useOffline = () => {
    const navigate = useNavigate();
    const disconnect = useRoomDisconnect();
    const timeoutRef = useRef();
    const handleOfflineTimeout = useCallback(() => {
        disconnect();
        navigate(ROUTES.rooms.path);
        modalStore.open({
            content: _jsx(OfflineModal, {}),
            onClose: () => { },
        });
    }, [disconnect, navigate]);
    const handleDisconnect = useCallback(() => {
        timeoutRef.current = setTimeout(handleOfflineTimeout, ON_OFFLINE_ACTION_DELAY_MS);
    }, [handleOfflineTimeout]);
    const handleConnect = useCallback(() => {
        clearTimeout(timeoutRef.current);
        modalStore.close();
        window.location.reload();
    }, []);
    useEffect(() => {
        window.addEventListener('offline', handleDisconnect);
        window.addEventListener('online', handleConnect);
        return () => {
            window.removeEventListener('offline', handleDisconnect);
            window.removeEventListener('online', handleConnect);
        };
    }, [handleConnect, handleDisconnect]);
};
