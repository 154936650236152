import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BC_MESSAGES } from '../../types';
import { connectionStore } from '@/shared/model/connection';
import { janusStore } from '@/shared/model/janus';
import { userStore } from '@/shared/model/user';
import { ROUTES } from '@/shared/constants/routes';
const BC_NAME = 'bc-name';
export const useHandleActiveInOtherTab = () => {
    const navigate = useNavigate();
    const { janus: { localStream }, } = janusStore;
    const { user: { id }, } = userStore;
    const bc = useMemo(() => new BroadcastChannel(BC_NAME), []);
    const handleMessages = useCallback((e) => {
        if (e.data.message === BC_MESSAGES.check) {
            if (localStream) {
                return bc.postMessage({
                    message: BC_MESSAGES.disconnect,
                    userId: id,
                });
            }
        }
        if (e.data.message === BC_MESSAGES.disconnect && e.data.userId === id) {
            return navigate(ROUTES.home.path);
        }
    }, [bc, id, localStream, navigate]);
    useEffect(() => {
        connectionStore.broadcastChannel = bc;
        bc.addEventListener('message', handleMessages);
        bc.postMessage({
            message: BC_MESSAGES.check,
            userId: id,
        });
        return () => bc.removeEventListener('message', handleMessages);
    }, [bc, handleMessages, id]);
};
