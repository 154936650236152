import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import SubmitButton from '@/shared/ui/SubmitButton';
import { speedDatingStore } from '@/shared/model/speed-dating';
export const ToggleSpeedDatingMatch = observer(() => {
    const { isMatched } = speedDatingStore;
    const handleToggleClick = useCallback(() => {
        speedDatingStore.isMatched = !isMatched;
    }, [isMatched]);
    return isMatched ? (_jsx(SubmitButton, { variant: 'contained', onClick: handleToggleClick, children: "Go to date view" })) : (_jsx(SubmitButton, { variant: 'contained', onClick: handleToggleClick, children: "Go to match view" }));
});
