import { makeAutoObservable, runInAction } from 'mobx';
import { sendInteraction, SendInteractionsMessages } from '../../interactions';
const clearJanus = {
    ready: false,
    room: {},
    videoRoom: {},
    newSubscription: () => ({ subscribe: async () => { }, unsubscribe: async () => { } }),
};
class JanusStore {
    _session = null;
    _janus = clearJanus;
    _videoRoom = {};
    _removeSession = null;
    _reconnect = null;
    _isMyMicMuted = false;
    _isMyVideoDisabled = false;
    _streams = [];
    _localStream = null;
    constructor() {
        makeAutoObservable(this, {}, { deep: false });
    }
    set isMyMicMuted(state) {
        this._isMyMicMuted = state;
    }
    get isMyMicMuted() {
        return this._isMyMicMuted;
    }
    set session(session) {
        if (this._session) {
            this._session.destroy?.();
        }
        this._session = session;
    }
    get session() {
        return this._session;
    }
    set isMyVideoDisabled(state) {
        this._isMyVideoDisabled = state;
    }
    get isMyVideoDisabled() {
        return this._isMyVideoDisabled;
    }
    get streams() {
        return this._streams;
    }
    addStream(stream) {
        const foundStream = this._streams.find((s) => s.id === stream.id);
        if (foundStream?.stream) {
            const videoTrack = stream.stream?.getVideoTracks()[0];
            const audioTrack = stream.stream?.getAudioTracks()[0];
            if (audioTrack) {
                foundStream.stream.getAudioTracks().forEach((t) => foundStream.stream?.removeTrack(t));
                foundStream.stream?.addTrack(audioTrack);
            }
            if (videoTrack) {
                foundStream.stream.getVideoTracks().forEach((t) => foundStream.stream?.removeTrack(t));
                foundStream.stream?.addTrack(videoTrack);
            }
            this._streams = [...this._streams];
            return;
        }
        this._streams = [...this._streams, stream];
        this._localStream = this._streams.find((s) => s.local) ?? null;
    }
    removeStream(streamId) {
        this._streams
            .find((s) => s.id === streamId)
            ?.stream?.getTracks()
            .forEach((t) => t.stop());
        this._streams = this._streams.filter((s) => s.id !== streamId);
    }
    removeAllStreams() {
        this._streams.forEach((s) => s.stream?.getTracks().forEach((t) => t.stop()));
        this._streams = [];
    }
    get localStream() {
        return this._localStream;
    }
    set removeSession(removeSession) {
        this._removeSession = removeSession;
    }
    get removeSession() {
        return this._removeSession;
    }
    set reconnect(reconnect) {
        this._reconnect = reconnect;
    }
    get reconnect() {
        return this._reconnect;
    }
    toggleAudioStream = () => {
        const tracks = this.streams.find((s) => s.local === true)?.stream?.getAudioTracks();
        if (!tracks || tracks.length < 1)
            return false;
        if (tracks[0].enabled) {
            // eslint-disable-next-line
            this.localStream?.stream?.getAudioTracks().forEach((s) => (s.enabled = false));
        }
        else {
            // eslint-disable-next-line
            this.localStream?.stream?.getAudioTracks().forEach((s) => (s.enabled = true));
        }
        this.isMyMicMuted = !tracks[0].enabled;
        return this.isMyMicMuted;
    };
    toggleVideoStream = (userId) => {
        const tracks = this.localStream?.stream?.getVideoTracks();
        if (!tracks)
            return false;
        if (tracks[0].enabled) {
            // eslint-disable-next-line
            this.localStream?.stream?.getVideoTracks().forEach((s) => (s.enabled = false));
        }
        else {
            // eslint-disable-next-line
            this.localStream?.stream?.getVideoTracks().forEach((s) => (s.enabled = true));
        }
        this._isMyVideoDisabled = !tracks[0].enabled;
        sendInteraction({
            method: SendInteractionsMessages.videoMute,
            message: {
                userId,
                muted: !tracks[0].enabled,
            },
        });
        return this._isMyVideoDisabled;
    };
    set janus(janus) {
        this._janus = janus;
    }
    get janus() {
        return this._janus;
    }
    set videoRoom(videoRoom) {
        this._videoRoom = videoRoom;
    }
    get videoRoom() {
        return this._videoRoom;
    }
    clear = () => {
        runInAction(() => {
            this.localStream?.stream?.getTracks().forEach((t) => t.stop());
            this._session?.destroy?.();
            this._session = null;
            this._janus = clearJanus;
            this._isMyMicMuted = false;
            this._isMyVideoDisabled = false;
        });
    };
}
export const janusStore = new JanusStore();
