import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { roomReconnectStore } from '../../model/store';
import { ReconnectsLimitModal } from '../../ui/ReconnectsLimitModal';
import { ROUTES } from '@/shared/constants/routes';
import { modalStore } from '@/shared/model/modal';
const RECONNECTS_LIMIT = 3;
export const useReconnectsLimit = () => {
    const { reconnectionCount } = roomReconnectStore;
    const navigate = useNavigate();
    useEffect(() => {
        if (reconnectionCount < RECONNECTS_LIMIT) {
            return;
        }
        roomReconnectStore.isReconnectsLimitExceeded = true;
        roomReconnectStore.reconnectInfo = null;
        roomReconnectStore.reconnectionCount = 0;
        navigate(ROUTES.rooms.path);
        modalStore.open({
            content: _jsx(ReconnectsLimitModal, {}),
        });
    }, [navigate, reconnectionCount]);
};
