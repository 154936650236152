import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { BackgroundMusicVolumeSlider, BackgroundMusicSliderWrapper, BackgroundMusicWrapper, SoundFormContent, Text, Title, VolumeMutedIcon, VolumeIcon, } from './styled';
import { userPreferencesStore } from '@/shared/model/user-preferences';
import StorageService from '@/shared/lib/utils/storage';
import { STORAGE_KEYS } from '@/shared/constants/common';
const BACKGROUND_MUSIC_MIN_VOLUME = 0;
const BACKGROUND_MUSIC_MAX_VOLUME = 0.05; // audio volume max = 1, but to better visual and UX selected to this value
const BACKGROUND_MUSIC_VOLUME_STEP = 0.001;
const SoundForm = observer(() => {
    const { roomBackgroundMusicVolume } = userPreferencesStore;
    const handleVolumeChange = useCallback((_, newValue) => {
        userPreferencesStore.roomBackgroundMusicVolume = newValue;
        StorageService.set(STORAGE_KEYS.backgroundMusicVolume, userPreferencesStore.roomBackgroundMusicVolume);
    }, []);
    return (_jsxs(SoundFormContent, { children: [_jsx(Title, { marginBottom: '12px', children: "Room" }), _jsxs(BackgroundMusicWrapper, { children: [_jsx(Text, { children: "Background music" }), _jsxs(BackgroundMusicSliderWrapper, { children: [_jsx(VolumeMutedIcon, {}), _jsx(BackgroundMusicVolumeSlider, { "aria-label": 'Volume', value: roomBackgroundMusicVolume, size: 'small', min: BACKGROUND_MUSIC_MIN_VOLUME, max: BACKGROUND_MUSIC_MAX_VOLUME, step: BACKGROUND_MUSIC_VOLUME_STEP, onChange: handleVolumeChange }), _jsx(VolumeIcon, {})] })] })] }));
});
export default memo(SoundForm);
