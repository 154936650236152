import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { MenuItem } from '@mui/material';
import { AdministrationFormContent, Title, JanusReconnectWrapper } from './styled';
import { ExtendedTextField } from '../ProfileForm/styled';
import StorageService from '@/shared/lib/utils/storage';
import { STORAGE_KEYS } from '@/shared/constants/common';
import { RoomReconnectLogic, roomReconnectStore } from '@/shared/model/room-reconnect';
const AdministrationForm = observer(() => {
    const { roomReconnectLogic } = roomReconnectStore;
    const handleChange = (e) => {
        roomReconnectStore.roomReconnectLogic = e.target.value;
        StorageService.set(STORAGE_KEYS.roomReconnect, roomReconnectStore.roomReconnectLogic);
    };
    return (_jsxs(AdministrationFormContent, { children: [_jsx(Title, { marginBottom: '12px', children: "Administration" }), _jsx(JanusReconnectWrapper, { children: _jsxs(ExtendedTextField, { fullWidth: true, select: true, label: 'Janus reconnect logic', value: roomReconnectLogic, onChange: handleChange, children: [_jsx(MenuItem, { value: RoomReconnectLogic.WITH_PAGE_RELOAD, children: "With refresh the page" }), _jsx(MenuItem, { value: RoomReconnectLogic.WITHOUT_PAGE_RELOAD, children: "Without refresh the page" })] }) })] }));
});
export default memo(AdministrationForm);
