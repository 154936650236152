import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, IconButton } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { LookingOnMeMark, LookingOnMeMarkIcon, StreamControls } from './styled';
import { HeaderMutedVideoIcon, HeaderVideoIcon, HeaderMutedAudioIcon, HeaderAudioIcon, } from '@/shared/assets/icons';
import { userStore } from '@/shared/model/user';
import { ROUTES } from '@/shared/constants/routes';
import { headerStore } from '@/shared/model/header';
import { pendingInteractionsStore } from '@/shared/model/interactions';
import roomPopupsStore from '@/shared/lib/store/roomPopups';
import { janusStore } from '@/shared/model/janus';
const UserStreamControls = observer(() => {
    const [isLookingOnMe, setIsLookingOnMe] = useState(false);
    const { pathname } = useLocation();
    const { lookedByUserC } = pendingInteractionsStore;
    const isLobby = pathname === ROUTES.rooms.path;
    const isDashboard = pathname === ROUTES.speakerDashboard.path;
    const { user, userMicMuted } = userStore;
    const { showControls, showCameraControl } = headerStore;
    const { isMyVideoDisabled } = janusStore;
    useEffect(() => {
        if (!lookedByUserC || lookedByUserC === user.id) {
            return setIsLookingOnMe(false);
        }
        setIsLookingOnMe(true);
    }, [lookedByUserC, user.id]);
    const handleChangeAudio = useCallback(() => {
        userStore.toggleMicMuted();
        janusStore.toggleAudioStream();
    }, []);
    const handleChangeVideo = useCallback(() => {
        janusStore.toggleVideoStream(user.id);
    }, [user.id]);
    const handleLookingOnMeClick = useCallback(() => {
        roomPopupsStore.findUser = lookedByUserC;
    }, [lookedByUserC]);
    return isLobby || isDashboard || !showControls ? null : (_jsxs(StreamControls, { children: [isLookingOnMe && (_jsx(Tooltip, { title: 'Click to find out who\u2019s checking you out', onClick: handleLookingOnMeClick, children: _jsx(LookingOnMeMark, { children: _jsx(LookingOnMeMarkIcon, {}) }) })), showCameraControl && (_jsx(Tooltip, { title: isMyVideoDisabled ? 'Enable camera' : 'Disable camera', onClick: handleChangeVideo, children: _jsx(IconButton, { size: 'small', color: 'white', children: isMyVideoDisabled ? _jsx(HeaderMutedVideoIcon, { color: 'error' }) : _jsx(HeaderVideoIcon, {}) }) })), _jsx(Tooltip, { title: userMicMuted ? 'Unmute mic' : 'Mute mic', children: _jsx(IconButton, { size: 'small', color: 'white', onClick: handleChangeAudio, children: userMicMuted ? _jsx(HeaderMutedAudioIcon, { color: 'error' }) : _jsx(HeaderAudioIcon, {}) }) })] }));
});
export default memo(UserStreamControls);
